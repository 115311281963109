export const loadedDocs = (
  response,
  interval,
  setIsModalOpen,
  showModal,
  setErrorText
) => {
  //   console.log(response);
  if (
    (response[1] === undefined && response[2].status === "COMPLETED") ||
    (response[1]?.every((item) => item === "COMPLETED") &&
      response[2].status === "COMPLETED")
  ) {
    clearInterval(interval);
    setIsModalOpen(false);
    showModal("creationSuccess");
    console.log("SUCCESS");
  }
  if (response[1]?.some((item) => item === "FAILURE")) {
    clearInterval(interval);
    setIsModalOpen(false);
    showModal("creationError");
    setErrorText(
      response[0].map((item) =>
        item.error_message === "File too large"
          ? `Файл ${item.name}.pdf слишком большой`
          : item.error_message
      )
    );
    console.log("FAILURE");
  }
  if (response[2]?.status === "FAILURE") {
    clearInterval(interval);
    setIsModalOpen(false);
    showModal("creationError");
    setErrorText(response[2].error_message);
    console.log("FAILURE");
  }
};
