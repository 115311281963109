import React from "react";
import { Button } from "antd";
import s from "./BotListItem.module.scss";
import userImage from "../../img/user.svg";
import addBotIcon from "../../img/plus.svg";

const BotListItem = ({ title, showModal, editBot, uuid, templateId }) => {
  return (
    <div>
      {title === "Создать бота" ? (
        <div className={s.createBot} onClick={showModal}>
          <div>
            <img src={addBotIcon} alt="user" />
            <span>{title}</span>
          </div>
        </div>
      ) : (
        <div className={s.listItem} onClick={() => editBot(templateId, uuid)}>
          <div>
            <img src={userImage} alt="user" />
            <span>{title}</span>
          </div>
          <Button type="text">Редактировать бота &#8594; </Button>
        </div>
      )}
    </div>
  );
};

export default BotListItem;
