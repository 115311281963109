export const catchErrors = (error, setIsModalOpen, setErrorText, showModal) => {
  //   console.log(error);
  setIsModalOpen(false);
  if (error.response.data.files) {
    error.response.data.files.find((item) =>
      item.non_field_errors ? setErrorText(item.non_field_errors) : null
    );
  }
  if (error.response.data.non_field_errors) {
    setErrorText(error.response.data.non_field_errors);
  }
  if (error.response.data.links) {
    error.response.data.links.find((item) =>
      item.link ? setErrorText(item.link) : null
    );
  }
  if (error.response.data.temperature) {
    setErrorText(error.response.data.temperature);
  }
  if (error.response.data.api_key) {
    setErrorText("Бот с таким токеном уже существует. Попробуйте другой токен");
  }
  showModal("creationError");
};
