import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components imports
import { Button, Modal, Select } from "antd";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import BotListItem from "../../components/BotListItem/BotListItem";

//css imports
import s from "./BotList.module.scss";
import "../../components/Modals/CreationLoader/CreationLoader.scss";

//image imports
import loader from "../../img/loader.png";

const BotList = ({ url, setIsAuth }) => {
  const navigate = useNavigate();

  const cookieToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("authtoken="))
    ?.split("=")[1];

  const [botRoles, setBotRoles] = useState([]);
  const [bots, setBots] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [botId, setBotId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    axios
      .get(`${url}/api/prompt-templates/`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        setBotRoles(
          response.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      });
  };
  const handleChange = (value) => {
    setBotId(value);
  };
  const handleOk = () => {
    if (botId) {
      setIsModalOpen(false);
      navigate(`/botsettings/:${botId}`);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const editBot = (templateId, uuid) => {
    navigate(`/botsettings/:${templateId}&${uuid}`);
  };
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/bots-template/`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        response.data.map((item) => {
          return setBots((bots) => [
            ...bots,
            {
              title: item?.title,
              uuid: item?.uuid,
              templateId: item?.template?.id,
            },
          ]);
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <div className={s.main_container}>
      <Sidebar />
      <div className={s.inner_container}>
        <Header url={url} setIsAuth={setIsAuth} />
        <div className={s.main}>
          <h2 className={s.title}>Мои чат-боты</h2>
          {bots &&
            bots.map((item) => (
              <BotListItem
                title={item.title}
                key={item.uuid}
                uuid={item.uuid}
                templateId={item.templateId}
                editBot={editBot}
              />
            ))}
          <BotListItem
            title={"Создать бота"}
            className={s.createBot}
            showModal={showModal}
          />
          <Modal
            title="🐣 Создайте нового бота"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="submit" onClick={handleOk}>
                Создать бота
              </Button>,
            ]}
          >
            <p>Роль вашего помощника</p>
            <Select
              defaultValue="Выберите шаблон"
              style={{ width: 300 }}
              onChange={handleChange}
              options={botRoles}
            />
          </Modal>
        </div>
      </div>
      <Modal
        title=" "
        open={isLoading}
        footer={[null]}
        closable={false}
        className={s.loader}
      >
        <img src={loader} alt="loader" />
      </Modal>
    </div>
  );
};

export default BotList;
