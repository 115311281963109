const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.originFileObj);
    fileReader.onload = () => {
      resolve({
        name: file.name.slice(0, -4),
        file: fileReader.result.slice(28),
      });
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const handleFileRead = async (
  event,
  setNumberOfFiles,
  setBodyParams
) => {
  const fileList = event.fileList;
  setNumberOfFiles(fileList.length);
  Promise.all(fileList.map((item) => convertBase64(item)))
    .then((fileItem) => {
      setBodyParams((bodyParams) => ({ ...bodyParams, files: fileItem }));
    })
    .catch((error) => console.log(error));
};
