import React from "react";
import { Modal } from "antd";
import "./CreationLoader.scss";
import loader from "../../../img/loader.png";

const CreationLoader = ({ isModalOpen, deleteLoader }) => {
  return (
    <div>
      {deleteLoader ? (
        <Modal
          title="Ваш бот удаляется"
          open={isModalOpen}
          footer={[null]}
          closable={false}
          className="creationLoader"
        >
          <img src={loader} alt="loader" />
        </Modal>
      ) : (
        <Modal
          title="Ваш бот создается"
          open={isModalOpen}
          footer={[null]}
          closable={false}
          className="creationLoader"
        >
          <p>
            Пожалуйста подождите, пока мы создаем вашего бота. Это не займет
            больше 2 минут.
          </p>
          <img src={loader} alt="" />
        </Modal>
      )}
    </div>
  );
};

export default CreationLoader;
