import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import s from "./Sidebar.module.scss";
import logo from "../../img/logo-2.svg";
import vector1 from "../../img/vector1.svg";
import vector2 from "../../img/vector2.svg";
import CancelChange from "../Modals/CancelChange/CancelChange";

const Sidebar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const params = useLocation();
  return (
    <div className={s.container}>
      <img
        src={logo}
        alt="logo"
        className={s.logo}
        onClick={() => {
          if (params.pathname !== "/botlist") {
            setIsModalOpen(true);
          }
        }}
      />
      <main className={s.main}>
        <div
          className={s.chatbots}
          onClick={() => {
            if (params.pathname !== "/botlist") {
              setIsModalOpen(true);
            }
          }}
        >
          <img src={vector1} alt="my-bots" />
          <span>Мои чат-боты</span>
        </div>
        <div className={s.help}>
          <img src={vector2} alt="support" />
          <span>Помощь</span>
        </div>
      </main>
      <footer className={s.footer}>
        <p>© 2023 Cleverbots. Все права защищены.</p>
      </footer>
      <CancelChange
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        setIsModalOpen={setIsModalOpen}
        url={"/botlist"}
      />
    </div>
  );
};

export default Sidebar;
