import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Select, Modal } from "antd";
import axios from "axios";

//components imports
import Sidebar from "../../components/Sidebar/Sidebar";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import FileInput from "../../components/Inputs/FileInput/FileInput";
import CustomSlider from "../../components/Slider/CustomSlider";
import CustomButtonBlue from "../../components/Buttons/CustomButtonBlue/CustomButtonBlue";
import CustomButtonWhite from "../../components/Buttons/CustomButtonWhite/CustomButtonWhite";
import CancelChange from "../../components/Modals/CancelChange/CancelChange";
import DeleteBot from "../../components/Modals/DeleteBot/DeleteBot";
import TokenModal from "../../components/Modals/TokenModal/TokenModal";
import CreationLoader from "../../components/Modals/CreationLoader/CreationLoader";
import CreationSuccess from "../../components/Modals/CreationSuccess/CreationSuccess";
import CreationError from "../../components/Modals/CreationError/CreationError";
import Header from "../../components/Header/Header";

//utils imports
import { handleFileRead } from "../../utils/handleFileRead";
import { uploadDocs } from "../../utils/uploadDocs";
import { loadedDocs } from "../../utils/loadedDocs";
import { catchErrors } from "../../utils/catchErrors";
import { setLinksBodyParams } from "../../utils/setLinksBodyParams";

//css imports
import s from "./BotSettings.module.scss";
import d from "../BotList/BotList.module.scss";

//image imports
import binIcon from "../../img/trashbin.svg";
import loader from "../../img/loader.png";

const BotSettings = ({ url, setIsAuth }) => {
  const cookieToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("authtoken="))
    ?.split("=")[1];

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { botId } = useParams();
  const { TextArea } = Input;

  const [deleteLoader, setDeleteLoader] = useState();
  const [errorText, setErrorText] = useState();
  const [numberOfFiles, setNumberOfFiles] = useState();
  const [variables, setVariables] = useState();
  const [bodyParams, setBodyParams] = useState({
    title: "",
    api_key: "",
    current_template_variables: {
      template: "",
      variables: { ...variables },
    },
    temperature: "",
    raw_texts: [
      {
        name: "raw_text",
        raw_text: "",
      },
    ],
  });
  const [isModalOpen, setIsModalOpen] = useState({
    cancelChange: false,
    creationLoader: false,
    creationError: false,
    deleteBot: false,
    creationSuccess: false,
    tokenModal: false,
    uploadError: false,
  });
  const [botName, setBotName] = useState();
  const [rawTextId, setRawTextId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (botId.length < 3) {
      setIsLoading(true);
      axios
        .get(`${url}/api/prompt-templates/${botId.slice(1, 2)}`, {
          headers: {
            Authorization: `Token ${cookieToken}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          response.data.variables.map((item) => {
            setVariables((variables) => ({
              ...variables,
              [item.variable_name]: item.variable_description,
            }));
            setBodyParams((bodyParams) => ({
              ...bodyParams,
              current_template_variables: {
                ...bodyParams.current_template_variables,
                variables: {
                  ...bodyParams.current_template_variables.variables,
                  [item.variable_name]: "",
                },
              },
            }));
          });
        });
    } else {
      setIsLoading(true);
      axios
        .get(`${url}/api/bots-template/${botId.slice(3)}`, {
          headers: {
            Authorization: `Token ${cookieToken}`,
          },
        })
        .then((response) => {
          //  console.log(response.data);
          setIsLoading(false);
          setBodyParams({
            title: response.data.title,
            api_key: response.data.api_key,
            current_template_variables: {
              template: response.data.current_template_variables.template,
              variables: response.data.current_template_variables.variables,
            },
            temperature: response.data.temperature,
            frequency_penalty: response.data.frequency_penalty,
            raw_texts: [
              {
                name: "raw_text",
                raw_text: response.data?.documents?.find(
                  (el) =>
                    el.document_type === "RAWTEXT" && el.status === "COMPLETED"
                )?.raw_text,
              },
            ],
          });
          setRawTextId(
            response.data.documents?.find(
              (el) => el.document_type === "RAWTEXT"
            )?.id
          );
        });
    }
  }, []);
  const showModal = (key, del) => {
    setIsModalOpen((isModalOpen) => ({ ...isModalOpen, [key]: true }));
    setDeleteLoader(del);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChange = (changedValues, allValues) => {
    setBodyParams(() => ({
      ...bodyParams,
      frequency_penalty: allValues.frequency_penalty,
    }));
    for (let key of Object.keys(bodyParams)) {
      if (key in allValues && key !== "files") {
        setBodyParams((bodyParams) => ({
          ...bodyParams,
          [key]: allValues[key],
        }));
      }
      if (key === "raw_texts" && allValues[key]) {
        setBodyParams((bodyParams) => ({
          ...bodyParams,
          [key]: [
            {
              name: "raw_text",
              raw_text: allValues[key],
            },
          ],
        }));
      }
      if (key === "raw_texts" && !allValues[key]) {
        setBodyParams((bodyParams) => ({
          ...bodyParams,
          [key]: [
            {
              name: "raw_text",
              raw_text: "",
            },
          ],
        }));
      }
      if (key === "title") {
        setBodyParams((bodyParams) => ({
          ...bodyParams,
          [key]: allValues["Name"],
        }));
      }
    }
    for (let key of Object.keys(
      bodyParams.current_template_variables.variables
    )) {
      if (key in allValues) {
        setBodyParams((bodyParams) => ({
          ...bodyParams,
          current_template_variables: {
            ...bodyParams.current_template_variables,
            variables: {
              ...bodyParams.current_template_variables.variables,
              [key]: allValues[key],
            },
          },
        }));
        setLinksBodyParams(key, setBodyParams, allValues);
      }
    }
    if (botId.length < 3) {
      setBodyParams((bodyParams) => ({
        ...bodyParams,
        current_template_variables: {
          ...bodyParams.current_template_variables,
          template: botId.slice(1, 2),
        },
      }));
    }
  };
  const onFinish1 = () => {
    showModal("creationLoader");
    axios
      .post(`${url}/api/bots-template/`, bodyParams, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        navigate(`/botsettings/${botId}&${response.data.uuid}`);
        if (bodyParams.files) {
          const interval = setInterval(() => {
            axios
              .get(`${url}/api/bots-template/${response.data.uuid}`, {
                headers: {
                  Authorization: `Token ${cookieToken}`,
                },
              })
              .then((response) => {
                return uploadDocs(
                  response,
                  numberOfFiles,
                  interval,
                  setBotName
                );
              })
              .then((response) => {
                loadedDocs(
                  response,
                  interval,
                  setIsModalOpen,
                  showModal,
                  setErrorText
                );
              });
          }, 1000);
        } else {
          setBotName(response.data.bot_username);
          setIsModalOpen(false);
          showModal("creationSuccess");
        }
      })
      .catch((error) => {
        console.log(error);
        catchErrors(error, setIsModalOpen, setErrorText, showModal);
      });
  };
  const onFinish2 = async () => {
    showModal("creationLoader");
    if (rawTextId) {
      await axios.delete(
        `${url}/api/bots-template/documents/rawtext/${rawTextId}/`,
        {
          headers: {
            Authorization: `Token ${cookieToken}`,
          },
        }
      );
    }
    await axios
      .put(`${url}/api/bots-template/${botId.slice(3)}/`, bodyParams, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        //   console.log(response);
        if (numberOfFiles || rawTextId) {
          const interval = setInterval(() => {
            axios
              .get(`${url}/api/bots-template/${botId.slice(3)}`, {
                headers: {
                  Authorization: `Token ${cookieToken}`,
                },
              })
              .then((response) => {
                //  console.log(response.data);
                return uploadDocs(
                  response,
                  numberOfFiles,
                  interval,
                  setBotName
                );
              })
              .then((response) => {
                //  console.log(response);
                loadedDocs(
                  response,
                  interval,
                  setIsModalOpen,
                  showModal,
                  setErrorText
                );
              });
          }, 1000);
        } else {
          setIsModalOpen(false);
          if (response.status === 200) {
            setBotName(response.data.bot_username);
            showModal("creationSuccess");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        catchErrors(error, setIsModalOpen, setErrorText, showModal);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //   console.log(bodyParams);
  return (
    <div className={s.main_container}>
      <Sidebar />
      <div className={s.inner_container}>
        <Header url={url} setIsAuth={setIsAuth} />
        <Form
          className={s.form}
          form={form}
          onFinish={botId.length < 3 ? onFinish1 : onFinish2}
          onFinishFailed={onFinishFailed}
          onValuesChange={onChange}
          fields={[
            {
              name: ["Name"],
              value: bodyParams.title,
            },
            {
              name: ["api_key"],
              value: bodyParams.api_key,
            },
            {
              name: ["Company"],
              value: bodyParams.current_template_variables.variables?.Company,
            },
            {
              name: ["Website"],
              value: bodyParams.current_template_variables.variables?.Website,
            },
            {
              name: ["Jobs"],
              value: bodyParams.current_template_variables.variables?.Jobs,
            },
            {
              name: ["HR_contacts"],
              value:
                bodyParams.current_template_variables.variables?.HR_contacts,
            },
            {
              name: ["Catalogue"],
              value: bodyParams.current_template_variables.variables?.Catalogue,
            },
            {
              name: ["Sales_contacts"],
              value:
                bodyParams.current_template_variables.variables?.Sales_contacts,
            },
            {
              name: ["Mood"],
              value: bodyParams.current_template_variables.variables?.Mood,
            },
            {
              name: ["Tone_of_voice"],
              value:
                bodyParams.current_template_variables.variables?.Tone_of_voice,
            },
            {
              name: ["temperature"],
              value: bodyParams.temperature,
            },
            {
              name: ["frequency_penalty"],
              value: bodyParams.frequency_penalty,
            },
            {
              name: ["raw_texts"],
              value: bodyParams.raw_texts[0]?.raw_text,
            },
          ]}
          requiredMark={false}
        >
          <TextInput
            label={"Имя вашего помощника"}
            name={"Name"}
            inputMessage={"Введите имя вашего помощника"}
          />
          <TextInput
            label={"Введите API Token вашего бота в Telegram"}
            name={"api_key"}
            tokenModal={"tokenModal"}
            showModal={showModal}
            inputMessage={"Введите API Token"}
          />
          <TokenModal
            isModalOpen={isModalOpen.tokenModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          <TextInput
            label={"Название вашей компании*"}
            name={"Company"}
            inputMessage={"Введите название компании"}
          />
          <TextInput
            label={"Сайт вашей компании*"}
            name={"Website"}
            inputMessage={"Некорректная ссылка"}
          />
          {(() => {
            switch (botId.slice(1, 2)) {
              case "1":
                return (
                  <>
                    <FileInput
                      label={
                        "Файл в формате .pdf с информацией про вашу компанию, продукты и услуги"
                      }
                      name={"files"}
                      handleFileRead={(e) =>
                        handleFileRead(e, setNumberOfFiles, setBodyParams)
                      }
                      cookieToken={cookieToken}
                      botId={botId}
                      url={url}
                    />
                    <Form.Item
                      name="frequency_penalty"
                      rules={[
                        { required: true, message: "Установите значение" },
                      ]}
                    >
                      <CustomSlider
                        name={"frequency_penalty"}
                        label={"Насколько богатой будет речь вашего помощника"}
                        minMark={"Умеренная"}
                        maxMark={"Богатая"}
                        min={0}
                        max={1}
                        step={0.1}
                      />
                    </Form.Item>
                  </>
                );
              case "2":
                return (
                  <>
                    <FileInput
                      label={
                        "Файл в формате .pdf с информацией про вашу компанию для соискателей"
                      }
                      name={"files"}
                      handleFileRead={(e) =>
                        handleFileRead(e, setNumberOfFiles, setBodyParams)
                      }
                      cookieToken={cookieToken}
                      botId={botId}
                      url={url}
                    />
                    <TextInput
                      label={"Ссылка на каталог ваших услуг и продуктов"}
                      name={"Catalogue"}
                      inputMessage={"Некорректная ссылка"}
                    />
                    <TextInput
                      label={
                        "Как можно с вами связаться в случае заинтересованности в покупке (ссылка / телефон / почта)"
                      }
                      name={"Sales_contacts"}
                      inputMessage={"Введите ваши контакты"}
                    />
                  </>
                );
              case "3":
                return (
                  <>
                    <FileInput
                      label={
                        "Файл в формате .pdf с информацией про вашу компанию, продукты и услуги"
                      }
                      name={"files"}
                      handleFileRead={(e) =>
                        handleFileRead(e, setNumberOfFiles, setBodyParams)
                      }
                      cookieToken={cookieToken}
                      botId={botId}
                      url={url}
                    />
                    <TextInput
                      label={"Ссылка на список актуальных вакансий	"}
                      name={"Jobs"}
                      inputMessage={"Некорректная ссылка"}
                    />
                    <TextInput
                      label={
                        "Как можно с вами связаться в случае заинтересованности в покупке (ссылка / телефон / почта)"
                      }
                      name={"HR_contacts"}
                      inputMessage={"Введите ваши контакты"}
                    />
                  </>
                );
              default:
                return null;
            }
          })()}
          <p className={s.label}>
            Расскажите, что еще бот должен знать про вашу компанию
          </p>
          <Form.Item name="raw_texts" colon={false}>
            <TextArea
              rows={4}
              placeholder="maxLength is 25000"
              maxLength={25000}
              className={s.input}
            />
          </Form.Item>
          <p className={s.label}>Выберите настроение вашего бота*</p>
          <Form.Item
            name="Mood"
            colon={false}
            rules={[{ required: true, message: "Выберите настроение" }]}
          >
            <Select className={s.input}>
              <Select.Option value="Радостное">Радостное</Select.Option>
              <Select.Option value="Деловое">Деловое</Select.Option>
              <Select.Option value="Веселое">Веселое</Select.Option>
              <Select.Option value="Спокойное">Спокойное</Select.Option>
              <Select.Option value="Активное">Активное</Select.Option>
              <Select.Option value="Грустное">Грустное</Select.Option>
              <Select.Option value="Тревожное">Тревожное</Select.Option>
              <Select.Option value="Тоскливое">Тоскливое</Select.Option>
              <Select.Option value="Сердитое">Сердитое</Select.Option>
            </Select>
          </Form.Item>
          <p className={s.label}>Выберите стиль общения вашего бота*</p>
          <Form.Item
            name="Tone_of_voice"
            colon={false}
            rules={[{ required: true, message: "Выберите стиль общения" }]}
          >
            <Select className={s.input}>
              <Select.Option value="Стандартный">Стандартный</Select.Option>
              <Select.Option value="Дружеский">Дружеский</Select.Option>
              <Select.Option value="Формальный">Формальный</Select.Option>
              <Select.Option value="Забавный">Забавный</Select.Option>
              <Select.Option value="Саркастический">
                Саркастический
              </Select.Option>
              <Select.Option value="Нравственный">Нравственный</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="temperature"
            rules={[{ required: true, message: "Установите значение" }]}
          >
            <CustomSlider
              name={"temperature"}
              label={"Насколько самостоятельным будет ваш помощник"}
              minMark={"Не самостоятельный"}
              maxMark={"Очень самостоятельный"}
              min={-2}
              max={2}
              step={0.4}
            />
          </Form.Item>
          {botId.length > 3 ? (
            <Form.Item>
              <CustomButtonWhite
                text={"Отменить изменения"}
                showModal={showModal}
              />
            </Form.Item>
          ) : null}

          <Form.Item>
            <CustomButtonBlue text={"Сохранить бота"} type={"submit"} />
          </Form.Item>
          {botId.length > 3 ? (
            <div className={s.deleteBot} onClick={() => showModal("deleteBot")}>
              <img src={binIcon} alt="trashbin" />
              <span>Удалить бота</span>
            </div>
          ) : null}
        </Form>
        <DeleteBot
          isModalOpen={isModalOpen.deleteBot}
          handleCancel={handleCancel}
          botId={botId}
          cookieToken={cookieToken}
          showModal={showModal}
          url={url}
        />
        <CancelChange
          isModalOpen={isModalOpen.cancelChange}
          handleCancel={handleCancel}
          setIsModalOpen={setIsModalOpen}
          url={0}
        />
        <CreationLoader
          isModalOpen={isModalOpen.creationLoader}
          deleteLoader={deleteLoader}
        />
        <CreationSuccess
          isModalOpen={isModalOpen.creationSuccess}
          handleCancel={handleCancel}
          setIsModalOpen={setIsModalOpen}
          name={bodyParams.title}
          botName={botName}
        />
        <CreationError
          isModalOpen={isModalOpen.creationError}
          handleCancel={handleCancel}
          errorText={errorText}
        />
        <Modal
          title=" "
          open={isLoading}
          footer={[null]}
          closable={false}
          className={d.loader}
        >
          <img src={loader} alt="loader" />
        </Modal>
      </div>
    </div>
  );
};

export default BotSettings;
