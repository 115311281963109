import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import axios from "axios";
import s from "./Registration.module.scss";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../img/logo.png";
import RegistrationSuccess from "../../components/Modals/RegistrationSuccess/RegistrationSuccess";

const Registration = ({url}) => {
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [IsLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    axios
      .post(`${url}/api/registration/`, {
        email: values.email,
        password1: values.password,
        password2: values.confirm,
        name: values.name,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 204) {
          setIsLoginModalOpen(true);
        }
      })
      .catch((error) => {
        setErrorMessage(
          Object.values(error.response.data).map((item) => item[0])
        );
      });
  };

  return (
    <div className={s.container}>
      <img src={logo} alt="logo" width={176} className={s.image} />
      <p className={s.title}>👤 Создание аккаунта</p>
      {errorMessage &&
        errorMessage.map((item) => (
          <div key={item} className={s.error}>
            {item}
          </div>
        ))}
      <Form
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        className={s.form}
      >
        <p className={s.label}>Имя</p>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Пожалуйста введите имя",
              whitespace: true,
            },
          ]}
          colon={false}
        >
          <Input className={s.input} />
        </Form.Item>
        <p className={s.label}>E-mail</p>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "E-mail введен некорректно",
            },
            {
              required: true,
              message: "Пожалуйста введите e-mail",
            },
          ]}
          colon={false}
        >
          <Input className={s.input} />
        </Form.Item>
        <p className={s.label}>Пароль</p>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Пожалуйста введите пароль",
            },
            {
              min: 8,
              message: "Пароль должен содержать минимум 8 символов",
            },
          ]}
          hasFeedback
          colon={false}
        >
          <Input.Password className={s.input} />
        </Form.Item>
        <p className={s.label}>Подтвердите пароль</p>
        <Form.Item
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Пожалуйста подтвердите пароль",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Пароли не совпадают"));
              },
            }),
          ]}
          colon={false}
        >
          <Input.Password className={s.input} />
        </Form.Item>
        <Form.Item className={s.button}>
          <Button type="primary" htmlType="submit">
            Создать аккаунт
          </Button>
        </Form.Item>
        <Form.Item className={s.button}>
          <Button type="primary" onClick={() => navigate("/authorization")}>
            Войти
          </Button>
        </Form.Item>
        <p className={s.info}>
          Пользуясь Cleverbots Studio вы подтверждаете свое{" "}
          <Link to="#">согласие на обработку персональных данных</Link>
        </p>
      </Form>
      <p className={s.info}>© 2023 Cleverbots. Все права защищены.</p>
      <RegistrationSuccess
        IsLoginModalOpen={IsLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
      />
    </div>
  );
};

export default Registration;
