import React from "react";
import { Button, Modal } from "antd";
import "./DeleteBot.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DeleteBot = ({
  showModal,
  isModalOpen,
  handleCancel,
  botId,
  cookieToken,
  url
}) => {
  const navigate = useNavigate();
  const deleteBotFunc = () => {
    showModal("creationLoader", "deleteLoader");
    axios
      .delete(`${url}/api/bots-template/${botId.slice(3)}`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        navigate("/botlist");
      });
  };
  return (
    <div>
      <Modal
        title="🛑 Вы уверены, что хотите удалить бота?"
        open={isModalOpen}
        onOk={deleteBotFunc}
        onCancel={handleCancel}
        footer={[null]}
        closable={false}
        className="deleteBot"
      >
        <p>
          В случае удаления, бота нельзя будет восстановить. Рекомендуем не
          удалять бота без везких причин
        </p>
        <Button className="continue" onClick={handleCancel}>
          Продолжить редактирование
        </Button>
        <Button className="delete" onClick={deleteBotFunc}>
          Удалить бота
        </Button>
      </Modal>
    </div>
  );
};

export default DeleteBot;
