import React from "react";
import { Upload, Form } from "antd";
import CustomButtonBlue from "../../Buttons/CustomButtonBlue/CustomButtonBlue";
import FileList from "../../FileList/FileList";
import s from "./FileInput.module.scss";

const FileInput = ({ cookieToken, label, name, handleFileRead, botId, url }) => {
  return (
    <>
      <p className={s.label}>{label}</p>
      <ul className={s.fileSize}>
        <li>Размер файла - не более 10 страниц</li>
      </ul>
      <Form.Item
        name={name}
        colon={false}
        valuePropName="filelist"
        className={s.formItem}
      >
        <Upload
          className={s.button}
          accept="application/pdf"
          beforeUpload={() => false}
          onChange={handleFileRead}
          multiple
        >
          <CustomButtonBlue text={"Загрузить файл"} type={"button"} />
        </Upload>
      </Form.Item>
      <FileList cookieToken={cookieToken} botId={botId} url={url}/>
    </>
  );
};

export default FileInput;
