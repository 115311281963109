import React from "react";
import { Button } from "antd";
import s from "./CustomButtonBlue.module.scss";

const CustomButtonBlue = (props) => {
  return (
    <div className={s.button}>
      <Button htmlType={props.type}>{props.text}</Button>
    </div>
  );
};

export default CustomButtonBlue;
