import React from "react";
import { Button, Modal } from "antd";
import "./CreationError.scss";
import errorIcon from "../../../img/error.svg";

const CreationError = ({ isModalOpen, handleCancel, errorText }) => {
  return (
    <div>
      <Modal
        title="Ошибка при создании бота"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[null]}
        closable={false}
        className="сreationError"
      >
        <p>
          Во время создания бота произошла ошибка. Пожалуйста, попробуйте
          заново.
        </p>
        <ul className="error">
          {Array.isArray(errorText) ? (
            errorText.map((item) => (
              <li>
                <img src={errorIcon} alt="errorIcon" />
                <p>{item}</p>
              </li>
            ))
          ) : (
            <li>
              <img src={errorIcon} alt="errorIcon" />
              <p>{errorText}</p>
            </li>
          )}
        </ul>
        <Button className="continue" onClick={handleCancel}>
          Вернуться к редактированию
        </Button>
        <Button className="help">Нужна помощь</Button>
      </Modal>
    </div>
  );
};

export default CreationError;
