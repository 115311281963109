export const setLinksBodyParams = (key, setBodyParams, allValues) => {
  if (key === "Website") {
    setBodyParams((bodyParams) => ({
      ...bodyParams,
      links: [{ link: allValues.Website }],
    }));
  }
  if (key === "Jobs") {
    setBodyParams((bodyParams) => ({
      ...bodyParams,
      links: [{ link: allValues.Website }, { link: allValues.Jobs }],
    }));
  }
  if (key === "Catalogue") {
    setBodyParams((bodyParams) => ({
      ...bodyParams,
      links: [{ link: allValues.Website }, { link: allValues.Catalogue }],
    }));
  }
};
