import React from "react";
import { Input, Form } from "antd";
import s from "./TextInput.module.scss";

const TextInput = ({ name, label, tokenModal, showModal, inputMessage }) => {
  return (
    <div className={s.container}>
      <div className={s.text}>
        <span className={s.label}>{label}</span>
        {tokenModal && (
          <span className={s.api} onClick={() => showModal(tokenModal)}>
            Как получить API Token Telegram?
          </span>
        )}
      </div>
      <Form.Item
        name={name}
        rules={[{ required: true, message: inputMessage }]}
        colon={false}
      >
        <Input className={s.input} />
      </Form.Item>
    </div>
  );
};

export default TextInput;
