import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import s from "./Header.module.scss";
import "./Header.css";
import userImage from "../../img/user.svg";
import axios from "axios";

const Header = ({ url, setIsAuth }) => {
  const cookieToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("authtoken="))
    ?.split("=")[1];
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState();

  useEffect(() => {
    axios
      .get(`${url}/api/users/`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((res) => setUserName(res.data[0].name));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsAuth(false);
    setIsModalOpen(false);
    document.cookie = `authtoken=; max-age=-1`;
    navigate("/");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={s.container}>
      <img
        src={userImage}
        alt="user"
        className={s.userImage}
        onClick={showModal}
      />
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className="userModal"
      >
        <p className="username">{userName}</p>
        <p onClick={handleOk} className="logout">
          Выйти
        </p>
      </Modal>
    </div>
  );
};

export default Header;
