import React from "react";
import { Button, Modal } from "antd";
import "./RegistrationSuccess.scss";
import botImage from "../../../img/bot-img.svg";
import { useNavigate } from "react-router-dom";

const RegistrationSuccess = ({ IsLoginModalOpen, setIsLoginModalOpen }) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    setIsLoginModalOpen(false);
  };
  return (
    <div>
      <Modal
        title="✅ Регистрация завершена"
        open={IsLoginModalOpen}
        onCancel={handleCancel}
        footer={[null]}
        closable={false}
        className="registrationSuccess"
      >
        <p>Ваш аккаунт успешно создан.</p>
        <Button className="login" onClick={() => navigate("/authorization")}>
          Войти в аккаунт
        </Button>
      </Modal>
    </div>
  );
};

export default RegistrationSuccess;
