import React from "react";
import { Button } from "antd";
import s from "./CustomButtonWhite.module.scss";

const CustomButtonWhite = ({ showModal, text }) => {
  return (
    <div className={s.button}>
      <Button
        onClick={() => {
          showModal("cancelChange");
        }}
      >
        {text}
      </Button>
    </div>
  );
};

export default CustomButtonWhite;
