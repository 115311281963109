export const uploadDocs = (response, numberOfFiles, interval, setBotName) => {
  let apiFiles = response.data.documents
    .filter((item) => item.name)
    .sort((a, b) => (a.id > b.id ? 1 : -1));
  let FilesToUpload =
    numberOfFiles > 0 ? apiFiles.slice(apiFiles.length - numberOfFiles) : null;
  let StatusArr = FilesToUpload?.map((item) => item.status);
  let rawText = response.data.documents.find(
    (item) => item.name === "raw_text"
  );
  if (!StatusArr?.includes("IN_PROGRESS") && rawText.status !== "IN_PROGRESS") {
    clearInterval(interval);
    console.log("FINISH");
  }
  setBotName(response.data.bot_username);
  return [FilesToUpload, StatusArr, rawText];
};
