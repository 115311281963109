import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import s from "./Auth.module.scss";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import logo from "../../img/logo.png";

const Auth = ({ url, setIsAuth }) => {
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const onFinish = (values) => {
    //  console.log("Success:", values);
    axios
      .post(`${url}/api/auth/login/`, {
        email: values.username,
        password: values.password,
      })
      .then((response) => {
        document.cookie = `authtoken=${response.data.key}; max-age=864000`;
        console.log(response);
        setIsAuth(true);
      })
      .then(() => navigate("/botlist", { replace: true }))
      .catch((error) => {
        error.response?.data?.email
          ? setErrorMessage(error.response?.data?.email)
          : setErrorMessage(error.response?.data?.non_field_errors);
        console.log(error);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className={s.container}>
      <img src={logo} alt="logo" width={176} className={s.image} />
      <p className={s.title}>👋 Добро пожаловать</p>
      {errorMessage && <div className={s.error}>{errorMessage}</div>}
      <Form
        requiredMark={false}
        className={s.form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <p className={s.label}>E-mail</p>
        <Form.Item
          name="username"
          rules={[
            {
              type: "email",
              message: "E-mail введен некорректно",
            },
            { required: true, message: "Пожалуйста введите e-mail" },
          ]}
          colon={false}
        >
          <Input className={s.input} />
        </Form.Item>
        <p className={s.label}>Пароль</p>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Пожалуйста введите пароль" }]}
          colon={false}
        >
          <Input.Password className={s.input} />
        </Form.Item>
        <Form.Item className={s.button}>
          <Button htmlType="submit">Войти</Button>
        </Form.Item>
        <Form.Item className={s.button}>
          <Button onClick={() => navigate("/registration")}>
            Создать аккаунт
          </Button>
        </Form.Item>
        <p className={s.info}>
          Пользуясь Cleverbots Studio вы подтверждаете свое{" "}
          <Link to="#">согласие на обработку персональных данных</Link>
        </p>
      </Form>
      <p className={s.info}>© 2023 Cleverbots. Все права защищены.</p>
    </div>
  );
};

export default Auth;
