import React from "react";
import { Button, Modal } from "antd";
import "./CancelChange.scss";
import { useNavigate } from "react-router-dom";

const CancelChange = ({ isModalOpen, handleCancel, setIsModalOpen, url }) => {
  const navigate = useNavigate();
  const cancelChangeFunc = () => {
    navigate(url);
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        title="Вы уверены, что хотите отменить изменения?"
        open={isModalOpen}
        onOk={cancelChangeFunc}
        onCancel={handleCancel}
        footer={[null]}
        closable={false}
        className="cancelChange"
      >
        <p>В случае отмены, последние изменения не будут сохранены</p>
        <Button className="continue" onClick={handleCancel}>
          Продолжить редактирование
        </Button>
        <Button className="cancel" onClick={cancelChangeFunc}>
          Отменить изменения
        </Button>
      </Modal>
    </div>
  );
};

export default CancelChange;
