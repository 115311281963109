import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import "./TokenModal.scss";
import noteIcon from "../../../img/note.svg";

const TokenModal = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      title="➕ Как получить API Token Telegram?"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[null]}
      closable={false}
      className="tokenModal"
    >
      <p>
        Для интеграции с Telegram, вам необходимо создать профиль чат-бота в
        Telegram и ввести его API Token.
      </p>
      <div className="note">
        <img src={noteIcon} alt="noteIcon" />
        <p>
          Важно: данный API Token не должен использоваться в других сервисах
          (Botmother, Chatfuel и т.д.)
        </p>
      </div>
      <p className="stepsTitle">
        Три шага для создания вашего бота в Telegram:
      </p>
      <ol className="steps">
        <li>
          Зайдите в бот{" "}
          <Link to="https://t.me/BotFather" target="_blank">
            @BotFather
          </Link>
          , нажмите кнопку “Start” и выберите команду “/newbot”
        </li>
        <li>
          Придумайте название бота (так его будут видеть пользователи) и
          username (адрес бота)
        </li>
        <li>
          После создания бота скопируйте его HTTP API Token, который прийдет в
          сообщение от @BotFather
        </li>
      </ol>
      <Button className="help">Нужна помощь</Button>
    </Modal>
  );
};

export default TokenModal;
