import React from "react";
import { Form, Slider } from "antd";
import "./CustomSlider.css";

const CustomSlider = (props) => {
  return (
    <>
      <p className="label">{props.label}</p>
      <div className="sliderMarks">
        <span>{props.minMark}</span>
        <span>{props.maxMark}</span>
      </div>
      <Form.Item name={props.name} colon={false}>
        <Slider
          min={props.min}
          max={props.max}
          step={props.step}
          dots={true}
          className="slider"
        />
      </Form.Item>
    </>
  );
};

export default CustomSlider;
