import React, { useEffect, useState } from "react";
import axios from "axios";
import s from "./FileList.module.scss";
import fileIcon from "../../img/fileIcon.svg";
import { useNavigate } from "react-router-dom";

const FileList = ({ botId, cookieToken, url }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    axios
      .get(`${url}/api/bots-template/${botId.slice(3)}`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        //   console.log(response);
        setFiles(
          response.data?.documents.filter(
            (item) =>
              item.status === "COMPLETED" && item.document_type === "FILE"
          )
        );
      });
  }, []);
  const deleteFile = (id) => {
    axios
      .delete(`${url}/api/bots-template/documents/file/${id}`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then(() => navigate(0))
      .catch((error) => console.log(error));
  };
  return (
    <div className={s.container}>
      {files.map((item) => (
        <div className={s.file} key={item.id} id={item.id}>
          <img src={fileIcon} alt="fileIcon" className={s.fileIcon} />
          <p className={s.fileName}>{item.name}.pdf</p>
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="delete"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
            className={s.delete}
            onClick={() => deleteFile(item.id)}
          >
            <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
          </svg>
        </div>
      ))}
    </div>
  );
};

export default FileList;
